import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/root'

const overviewSelector = (state: RootState) => state.kaseOverview

/**
 * Get the email of the account owner
 */
export const getAccountHolderEmail = createSelector(
  overviewSelector,
  (overview) => {
    return overview.information.find((i) => i.key === 'Account Holder Email')
      ?.value
  }
)
