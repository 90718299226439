import React, { FunctionComponent, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import { RootState } from 'store/root'
import cx from 'classnames'

import { fetchOverview, updateKaseOverview } from 'api/overview'
import { clearKaseCache } from 'api/kaseData'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import { fetchKaseStatesAndEvents, sendStateEvent } from 'api/stateEvent'
import ROUTES, {
  getRouteForKaseOverview,
  getRouteForStatesAndEvents
} from 'constants/routes'
import { KaseStates, KaseStateValues } from 'constants/kaseStates'
import {
  KaseTiers,
  KaseTierValues,
  KaseTierCssColorClasses
} from 'constants/kaseTiers'

import Button from 'components/Button'
import Count, { CountVariants } from 'components/Count'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import BlankModal from 'components/modals/BlankModal'
import SelectField from 'components/forms/SelectField'
import { useGlobalError } from 'components/errors/GlobalErrorWrapper'
import TextInput from 'components/forms/TextInput'
import { KaseStateEvent } from 'types/kaseStateEvent'
import CheckIcon from '../icons/CheckIcon'
import XIcon from '../icons/XIcon'
import { DocumentStates } from 'constants/documents'
import { getNonNullString } from 'utils/format_utils'

export interface SubNavigationProps {
  context: string
  showIssuePanel?: boolean
  isToggleDisabled?: boolean
  reviewType?: number
  isBulkUpdateStatusBtnsDisabled?: boolean
  showBulkUpdateStatusBtns?: boolean
  onBulkUpdateStatusBtnClicked?: (status: string) => void
  onRequestShowOrHideIssuesPanel?: () => void
  onRequestCreateIssue?: () => void
  onRequestChangeReviewType?: (event: any) => void
}

const SubNavigation: FunctionComponent<SubNavigationProps> = ({
  context,
  showIssuePanel,
  reviewType,
  isBulkUpdateStatusBtnsDisabled,
  isToggleDisabled = false,
  showBulkUpdateStatusBtns = false,
  onBulkUpdateStatusBtnClicked,
  onRequestShowOrHideIssuesPanel,
  onRequestCreateIssue,
  onRequestChangeReviewType
}) => {
  const history = useHistory()
  const { setGlobalError } = useGlobalError()
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const {
    kaseData,
    kaseIssues,
    kaseOverview,
    reviewTypes,
    kaseStateEvents: allStateTransitions
  } = useSelector((state: RootState) => state)

  const trackingNumber = kaseOverview?.information?.find(
    (i) => i.key === 'Package Tracking Number'
  )

  const [
    showChooseIssuesRecipientModal,
    setShowChooseIssuesRecipientModal
  ] = useState(false)

  const [modifiedTrackingNumber, setModifiedTrackingNumber] = useState('')

  const [
    currentTransition,
    setCurrentTransition
  ] = useState<KaseStateEvent | null>(null)

  useEffect(() => {
    fetchKaseStatesAndEvents(kaseId)
    const interval = setInterval(() => {
      fetchOverview(kaseId)
      fetchKaseStatesAndEvents(kaseId)
    }, 15 * 60 * 1000) // every 15 minutes if component is still mounted

    return () => clearInterval(interval)
  }, [kaseId])

  const handleConfirmButtonClick = (stateEvent: string): void => {
    setCurrentTransition(null)
    setShowChooseIssuesRecipientModal(false)

    sendStateEvent(kaseId, stateEvent)
      .then(() => fetchOverview(kaseId))
      .then(() => history.push(ROUTES.TASKS_ROOT))
      .catch((error) => setGlobalError(error))
  }

  const handleSetRecipient = (transition: KaseStateEvent): void => {
    setCurrentTransition(transition)
    setShowChooseIssuesRecipientModal(false)
  }

  const handleOnClickClearCache = () => {
    clearKaseCache(kaseId).then(() => window.location.reload())
  }

  // Generate possible Review type options for drop down menu
  let reviewTypesSelector: { label: any; value: any }[] = []

  if (Object.keys(reviewTypes.byId).length > 0) {
    reviewTypesSelector = Object.values(reviewTypes.byId).map((reviewType) => {
      return { label: reviewType.name, value: reviewType.id }
    })
  }

  // Find the number of open issues on the Kase to display
  const numOpenIssues: number = kaseIssues.issues.allIds.filter(
    (id: string) => kaseIssues.issues.byId[id].attributes.status === 'open'
  ).length

  // Find any state transitions that a customer could make based on their
  // current state: these are the relevant state transitions
  const allRelevantStateTransitions: KaseStateEvent[] = allStateTransitions.filter(
    (transition) => {
      return transition.relevant
    }
  )

  const relevantSendIssuesTransitions: KaseStateEvent[] = allRelevantStateTransitions.filter(
    (transition) => transition.buttonLabel.includes('Send Issues')
  )

  const legalReviewCompleteTransition:
    | KaseStateEvent
    | undefined = allRelevantStateTransitions.find((transition) => {
    return transition.event === 'legal_review_complete'
  })

  const eventTransitionButtons =
    legalReviewCompleteTransition &&
    relevantSendIssuesTransitions.length === 2 ? (
      <>
        <Button
          className="mb-2 mr-4 sm:mb-2 md:mb-0"
          key={`send-issues-button`}
          onClick={() => setShowChooseIssuesRecipientModal(true)}
        >
          Send Issues
        </Button>
        <Button
          className="mb-2 mr-4 sm:mb-2 md:mb-0"
          key="printer_packaging-button"
          onClick={() => setCurrentTransition(legalReviewCompleteTransition)}
        >
          {legalReviewCompleteTransition.buttonLabel}
        </Button>
      </>
    ) : (
      allRelevantStateTransitions.map((transition, index) => (
        <Button
          className="mb-2 mr-4 sm:mb-2 md:mb-0"
          key={`${transition.to}-button-${index}`}
          onClick={() => setCurrentTransition(transition)}
        >
          {transition.buttonLabel}
        </Button>
      ))
    )

  // We only show current state on certain admin screens, listed here
  const showCurrentState =
    context === 'overview' || context === 'states-and-events'

  // We only show current state on certain admin screens, listed here
  const showCurrentTier =
    context === 'overview' || context === 'states-and-events'

  // We only show the set of Ops buttons in certain tool contexts, listed here
  const showOpsButtons =
    context === 'document-review-tool' || context === 'form-review-tool'

  // Kind of a crummy way to figure this out, but we want to be able to know
  // if the kase is in a state that is owned by ops or by the customer - if its
  // in the customers hands, we don't display the send issues or state change
  // buttons - upgrade would be to get this value from the backend
  const customersTurnToAct =
    (kaseOverview.state.includes('customer') ||
      kaseOverview.state === 'post_payment') &&
    kaseOverview.state !== 'customer_package_shipped' &&
    kaseOverview.state !== 'customer_completed'

  // List of states the create issue button should be hidden
  const showCreateIssueButton =
    kaseOverview.state.length > 0 &&
    ![
      KaseStates.PRE_PAYMENT,
      KaseStates.POST_PAYMENT,
      KaseStates.PENDING_REFUND,
      KaseStates.REFUNDED,
      KaseStates.PRINTER_PACKAGING,
      KaseStates.CUSTOMER_PACKAGE_SHIPPED,
      KaseStates.CUSTOMER_COMPLETED
    ].includes(kaseOverview.state as KaseStates)

  const needsTrackingNumber =
    currentTransition?.to === 'customer_package_shipped' && !trackingNumber

  // In the future a kase may be in the middle of a longer conversion chain, so default to converted_to since recent is more likely to be relevant
  const linkedConversionKaseId =
    kaseOverview.converted_to_kase_id || kaseOverview.converted_from_kase_id

  const saveKaseOverviewValue = (key: any, value: any) => {
    updateKaseOverview(kaseOverview.kaseId, key, value)
  }

  const trackingNumberContent = () => {
    return (
      <>
        <p className="text-sm text-gray-600 leading-5 my-3">
          Please enter a <strong>tracking number</strong> to move the customer
          into this state.
        </p>
        <TextInput
          className="flex-2"
          onChange={(event) => setModifiedTrackingNumber(event.target.value)}
          placeholder="Add tracking number here"
        />
      </>
    )
  }

  const cautionIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className="w-5 h-5 mb-1 inline-flex text-blue-600"
    >
      <path
        fillRule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
        clipRule="evenodd"
      />
    </svg>
  )

  const saveWithTrackingNumber = () => {
    saveKaseOverviewValue('package_tracking_number', modifiedTrackingNumber)
    handleConfirmButtonClick(currentTransition?.event as string)
  }

  return (
    <nav className="border-b border-gray-200 bg-white py-2">
      <div className="h-12 px-8">
        <div className="float-left pr-12">
          <Link
            data-testid="overview-link"
            to={getRouteForKaseOverview(kaseId)}
            className="underline hover:no-underline font-normal truncate"
          >
            {kaseOverview.contact_info.sponsor_name} (S) <br />
            {kaseOverview.contact_info.beneficiary_name} (B)
          </Link>
        </div>
        <div className="float-left pr-12">
          <Link
            to={`/users/${kaseData.attributes?.user_id}`}
            className="underline hover:no-underline font-normal truncate"
          >
            View User
          </Link>
        </div>
        {linkedConversionKaseId && (
          <div className="float-left pr-12">
            <Link
              to={getRouteForKaseOverview(linkedConversionKaseId)}
              className="underline hover:no-underline font-normal truncate"
            >
              Loyal Customer's Other Kase
            </Link>
          </div>
        )}
        <div className="float-left pr-8 pt-1">
          {context === 'form-review-tool' && onRequestChangeReviewType && (
            <SelectField
              className="mr-8"
              id="review-type-selector"
              onChange={(event) => onRequestChangeReviewType(event)}
              options={reviewTypesSelector}
              value={reviewType}
              disabled={isToggleDisabled}
            />
          )}
        </div>
        <div className="float-left pr-4 pt-2">
          <Button
            className="pt-2 mb-2 mr-4 sm:mb-2 md:mb-0 "
            variant="plain"
            onClick={() => handleOnClickClearCache()}
          >
            Clear Kase Cache
          </Button>
        </div>
        <div className="float-right">
          <div className="flex items-center">
            {showCurrentState && (
              <div data-testid="current-state" className="text-xs">
                Current State (
                <Link
                  to={getRouteForStatesAndEvents(kaseId)}
                  className="underline hover:no-underline text-xs font-medium"
                >
                  Update
                </Link>
                )
                <div className="text-sm font-bold">
                  {KaseStateValues[kaseOverview.state as KaseStates] ||
                    kaseOverview.state}
                </div>
              </div>
            )}
          </div>
          {showOpsButtons &&
            onRequestShowOrHideIssuesPanel &&
            onRequestCreateIssue && (
              <div className="flex items-center pt-1">
                {showBulkUpdateStatusBtns && onBulkUpdateStatusBtnClicked && (
                  <>
                    <Button
                      className="pt-2 mb-2 mr-4 sm:mb-2 md:mb-0 "
                      disabled={isBulkUpdateStatusBtnsDisabled}
                      variant="plain"
                      onClick={() =>
                        onBulkUpdateStatusBtnClicked(DocumentStates.APPROVED)
                      }
                    >
                      <span
                        className={cx('flex items-center', {
                          'text-green-200': isBulkUpdateStatusBtnsDisabled,
                          'text-green-600': !isBulkUpdateStatusBtnsDisabled
                        })}
                      >
                        <Button.Icon placement="before">
                          <CheckIcon />
                        </Button.Icon>
                        Accept
                      </span>
                    </Button>
                    <Button
                      className="pt-2 mb-2 mr-4 sm:mb-2 md:mb-0 "
                      disabled={isBulkUpdateStatusBtnsDisabled}
                      variant="plain"
                      onClick={() =>
                        onBulkUpdateStatusBtnClicked(DocumentStates.DISMISSED)
                      }
                    >
                      <span
                        className={cx('flex items-center', {
                          'text-gray-300': isBulkUpdateStatusBtnsDisabled,
                          'text-gray-600': !isBulkUpdateStatusBtnsDisabled
                        })}
                      >
                        <Button.Icon placement="before">
                          <XIcon />
                        </Button.Icon>
                        Dismiss
                      </span>
                    </Button>
                  </>
                )}
                <Button
                  id="hide-view-issues-btn"
                  className="pt-2 mb-2 mr-4 sm:mb-2 md:mb-0"
                  variant="plain"
                  onClick={() => onRequestShowOrHideIssuesPanel()}
                >
                  {showIssuePanel ? 'Hide' : 'View'} Issues{' '}
                  <Count variant={CountVariants.PRIMARY}>{numOpenIssues}</Count>
                </Button>

                <span>
                  {showCreateIssueButton && (
                    <Button
                      data-testid="create-issue-btn"
                      className="mb-2 mr-4 sm:mb-2 md:mb-0"
                      variant="primary"
                      onClick={() => onRequestCreateIssue()}
                    >
                      Create Issue
                    </Button>
                  )}
                  {!customersTurnToAct && eventTransitionButtons}
                </span>
              </div>
            )}
        </div>
        <div className="float-right pr-8">
          <div className="flex items-center">
            {showCurrentTier && (
              <div data-testid="current-tier" className="text-xs">
                Service Tier
                <div className="text-sm font-bold">
                  <span
                    className={
                      'mt-1 p-1 text-white rounded-sm ' +
                      KaseTierCssColorClasses[
                        getNonNullString(kaseOverview.tier) as KaseTiers
                      ]
                    }
                  >
                    {
                      KaseTierValues[
                        getNonNullString(kaseOverview.tier) as KaseTiers
                      ]
                    }
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <BlankModal
        isOpen={showChooseIssuesRecipientModal}
        onRequestClose={() => setShowChooseIssuesRecipientModal(false)}
      >
        <div className="bg-white p-6 rounded-lg shadow-xl">
          <h3 className="text-lg text-gray-800 leading-6 font-medium mb-2">
            {cautionIcon} Send issues to who?
          </h3>
          {relevantSendIssuesTransitions.map((transition) => (
            <Button
              className="pt-2 mb-2 mr-4 sm:mb-2 md:mb-0"
              variant="primary"
              key={transition.to}
              onClick={() => handleSetRecipient(transition)}
            >
              {transition.buttonLabel.replace('Send Issues to ', '')}
            </Button>
          ))}
          <Button
            className="pt-2 mb-2 mr-4 sm:mb-2 md:mb-0"
            onClick={() => setShowChooseIssuesRecipientModal(false)}
          >
            Cancel
          </Button>
        </div>
      </BlankModal>

      {currentTransition && needsTrackingNumber && (
        <ConfirmationModal
          cancelLabel="Cancel"
          confirmDisabled={!modifiedTrackingNumber}
          confirmLabel={currentTransition.buttonLabel}
          description={currentTransition.opsDescription}
          isOpen={!!currentTransition && needsTrackingNumber}
          modalWidth="30rem"
          onConfirm={saveWithTrackingNumber}
          onRequestClose={() => setCurrentTransition(null)}
          title={currentTransition.buttonLabel}
        >
          {trackingNumberContent()}
        </ConfirmationModal>
      )}

      {currentTransition && !needsTrackingNumber && (
        <ConfirmationModal
          cancelLabel="Cancel"
          confirmLabel={currentTransition.buttonLabel}
          description={currentTransition.opsDescription}
          isOpen={!!currentTransition}
          modalWidth="30rem"
          onConfirm={() => handleConfirmButtonClick(currentTransition.event)}
          onRequestClose={() => setCurrentTransition(null)}
          title={currentTransition.buttonLabel}
        />
      )}
    </nav>
  )
}

export default SubNavigation
