import React, { FunctionComponent, useState } from 'react'
import SelectField from 'components/forms/SelectField'
import ConfirmationModal from 'components/modals/ConfirmationModal'
import { AdminUser } from 'types/users'

interface UserSelectOption {
  label: string
  value: string
}

interface Props {
  availableMembers?: AdminUser[]
  className?: string
  disabled?: boolean
  initiallySelectedMemberId?: number
  memberType: string
  updateSelectedMember: (memberId: number) => void
}

const KaseAssignmentDropdown: FunctionComponent<Props> = ({
  availableMembers,
  className,
  disabled,
  initiallySelectedMemberId,
  memberType,
  updateSelectedMember
}) => {
  const [selectedMember, setSelectedMember] = useState(
    initiallySelectedMemberId
  )
  const [showConfirmation, setShowConfirmation] = useState(false)

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedMember(parseInt(event.target.value))
    setShowConfirmation(true)
  }

  const handleReassignment = () => {
    if (selectedMember) updateSelectedMember(selectedMember)
    setShowConfirmation(false)
  }

  const handleCancelReassignment = () => {
    setSelectedMember(initiallySelectedMemberId)
    setShowConfirmation(false)
  }

  const selectOptions: UserSelectOption[] = availableMembers
    ? availableMembers.map((member: AdminUser) => {
        return { value: member.id, label: member.attributes.full_name }
      })
    : [
        {
          value: '-1',
          label: 'Loading...'
        }
      ]

  if (!initiallySelectedMemberId) {
    selectOptions.unshift({
      value: '-2',
      label: 'Not yet assigned'
    })
  }

  const getSelectedMemberName = () => {
    return selectOptions.find(
      (option) => parseInt(option.value) === selectedMember
    )?.label
  }

  return (
    <div className={className}>
      <h3 className="font-bold text-base">Assigned {memberType}</h3>
      <div className="w-64 mt-2">
        <SelectField
          disabled={disabled}
          id="case-log-type-selector"
          inline={true}
          options={selectOptions}
          onChange={onSelectChange}
          value={selectedMember}
        />
      </div>
      <ConfirmationModal
        isOpen={showConfirmation}
        title={`Change assigned ${memberType}?`}
        description={`Clicking Yes will reassign this case to ${getSelectedMemberName()}. Are you sure you want to do this?`}
        confirmLabel="Yes"
        onConfirm={handleReassignment}
        onRequestClose={handleCancelReassignment}
      />
    </div>
  )
}

export default KaseAssignmentDropdown
