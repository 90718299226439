import React, { FunctionComponent, useEffect, useState } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import IKaseIndexRouteParams from 'utils/IKaseIndexRouteParams'
import { completePayment } from 'api/payment'
import BlankModal from 'components/modals/BlankModal'
import LoadingSpinner from 'components/LoadingSpinner'
import Alert from 'components/Alert'
import Button from 'components/Button'
import { RootState } from 'store/root'
import { useSelector } from 'react-redux'

const SaveCompletedPayment: FunctionComponent = () => {
  const { kaseId } = useParams<IKaseIndexRouteParams>()
  const kaseOverview = useSelector((state: RootState) => state.kaseOverview)
  const { search } = useLocation()
  const { push } = useHistory()

  const [paymentIntent, setPaymentIntent] = useState<string | null>()

  const [
    isPaymentCompletedModalOpen,
    setIsPaymentCompletedModalOpen
  ] = useState(false)

  const [isSavingPaymentOnKase, setIsSavingPaymentOnKase] = useState(false)

  const [errorSavingPayment, setErrorSavingPayment] = useState<Error | null>()

  // Captures the Stripe payment confirmation info that is returned
  // as query strings in the url.
  useEffect(() => {
    const queryParams = queryString.parse(search)
    const stripe_payment_id = queryParams.payment_intent as string
    const isSplititPayment = queryParams.payment_type === 'splitit'
    const paymentRecorded = kaseOverview.state === 'post_payment'
    if (
      stripe_payment_id &&
      queryParams.redirect_status === 'succeeded' &&
      !paymentRecorded
    ) {
      savePaymentToKase(stripe_payment_id, 'stripe')
    } else if (
      isSplititPayment &&
      queryParams.splitit_success &&
      !paymentRecorded
    ) {
      const splitit_plan_number = queryParams.plan_number as string
      if (splitit_plan_number) {
        savePaymentToKase(splitit_plan_number, 'splitit')
      }
    }
  }, [])

  const savePaymentToKase = (
    paymentId: string,
    paymentType: 'splitit' | 'stripe'
  ) => {
    setPaymentIntent(paymentId)
    setIsSavingPaymentOnKase(true)
    setIsPaymentCompletedModalOpen(true)
    completePayment(kaseId, paymentId, paymentType)
      .then(() => {
        setIsSavingPaymentOnKase(false)
      })
      .catch((error: Error) => {
        setErrorSavingPayment(error)
        setIsSavingPaymentOnKase(false)
      })
  }

  const confirmPaymentCompleted = () => {
    setIsPaymentCompletedModalOpen(false)
    removePaymentQueryParams()
  }

  const removePaymentQueryParams = () => {
    const queryParams = queryString.parse(search)
    delete queryParams.redirect_status
    delete queryParams.payment_intent
    delete queryParams.payment_intent_client_secret
    delete queryParams.payment_type
    delete queryParams.plan_number
    delete queryParams.splitit_success
    const stringifiedQueryParams = queryString.stringify(queryParams)
    push({ search: stringifiedQueryParams })
  }

  return (
    <BlankModal
      isOpen={isPaymentCompletedModalOpen}
      onRequestClose={() => setIsPaymentCompletedModalOpen(false)}
    >
      <div className="bg-white p-6 rounded-lg shadow-xl">
        <h2 className="font-bold text-base mb-8">Saving Successful Payment</h2>

        {isSavingPaymentOnKase && (
          <>
            <LoadingSpinner />
            <p className="mt-8 text-sm text-gray-600 leading-5 pr-5">
              We are updating our records to show that this customer has paid.
            </p>
          </>
        )}

        {!isSavingPaymentOnKase && errorSavingPayment && (
          <>
            <Alert
              variant="error"
              title={`There was an error saving this payment in our records. ${errorSavingPayment.message}`}
            />

            <p className="my-4">
              If you continue to get an error after retrying, please notify
              Engineering with the following info so we can make sure the
              customers payment is recorded:
            </p>
            <ul>
              <li>Payment ID: {paymentIntent}</li>
              <li>Application ID: {kaseId}</li>
            </ul>

            <Button
              className="mt-8"
              variant="primary"
              onClick={() => window.location.reload()}
            >
              Retry Saving Payment
            </Button>
          </>
        )}

        {!isSavingPaymentOnKase && !errorSavingPayment && (
          <>
            <Alert
              variant="success"
              title="We have updated our records to show that this customer has paid."
            >
              <p className="">Registration is complete, thanks!</p>
            </Alert>
            <Button
              className="mt-8 space-x-3"
              variant="primary"
              onClick={confirmPaymentCompleted}
            >
              Close
            </Button>
          </>
        )}
      </div>
    </BlankModal>
  )
}

export default SaveCompletedPayment
